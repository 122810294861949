import { HomePage } from '@/lib/strapi-types/HomePage';

import { UseFindOneEntityProps } from '@/lib/types/data';
import { BackEndpoints } from '@/lib/types/enums/backEndpoints.enum';

export async function useHomePage({ filters, populate }: UseFindOneEntityProps) {
  const { findOne } = useStrapi<HomePage['attributes']>();

  const defaultPopulate = {
    themesSection: {
      populate: {
        inspirations: {
          fields: ['title', 'slug', 'icon', 'description'],
          populate: {
            image: { fields: ['url'] }
          }
        }
      }
    },
    inspirationSection: {
      fields: ['title', 'link'],
      populate: {
        imageAndTexts: {
          fields: ['text'],
          populate: {
            image: {
              fields: ['url']
            }
          }
        }
      }
    },
    marketingPushSection: {
      fields: ['title', 'subtitle'],
      populate: {
        geoZone: {
          fields: ['slug']
        },
        logo: {
          fields: ['url']
        },
        image: {
          fields: ['url']
        },
        CTA: {
          fields: ['label', 'url']
        }
      }
    },
    groupsSection: {
      fields: ['title', 'subtitle'],
      populate: {
        cards: {
          fields: ['title', 'buttonText', 'buttonLink'],
          populate: {
            image: {
              fields: ['url']
            }
          }
        },
        CTA: {
          fields: ['label', 'url']
        }
      }
    },
    imageAndTextSection: {
      fields: ['title', 'description'],
      populate: {
        image: {
          fields: ['url']
        },
        icon: {
          fields: ['url']
        },
        CTA: {
          fields: ['label', 'url']
        }
      }
    },
    otherDestinationsMapSection: {
      populate: {
        countries: {
          fields: ['name', 'slug'],
          populate: {
            geographicZones: {
              fields: ['slug']
            }
          }
        }
      }
    },
    meta: { fields: ['*'] }
  };
  const { data: homePageData } = await useAsyncData(BackEndpoints.HOME_PAGE, () =>
    findOne(BackEndpoints.HOME_PAGE, {
      filters,
      populate: { ...defaultPopulate, ...populate }
    })
  );

  return {
    themesSection: homePageData.value?.data.attributes.themesSection,
    inspirationSection: homePageData.value?.data.attributes.inspirationSection,
    marketingPushSection: homePageData.value?.data.attributes.marketingPushSection,
    groupsSection: homePageData.value?.data.attributes.groupsSection,
    imageAndTextSection: homePageData.value?.data.attributes.imageAndTextSection,
    heroTitle: homePageData.value?.data.attributes.hero.title,
    heroPlaceholder: homePageData.value?.data.attributes.hero.placeholder,
    heroPrimaryButton: homePageData.value?.data.attributes.hero.primaryButton,
    heroSecondaryButton: homePageData.value?.data.attributes.hero.secondaryButton,
    heroCountryIsoCodes: homePageData.value?.data.attributes.hero.countries.data.map(
      country => country.attributes.iso
    ),
    otherDestinationsMapSection: homePageData.value?.data.attributes.otherDestinationsMapSection,
    meta: homePageData.value?.data.attributes.meta
  };
}
